import {Layout} from '../../components/layout';
import * as styles from './index.module.css';

const AnalyticsREEMEAPage = () => (
    <Layout title="Analytics (CEEMEA)" scope="analytics.reemea">
        <div className={styles.wrapper}>
            <iframe
                title="REEMEA_YT Quarterly Performace Dashboard_v4"
                width="100%"
                height="100%"
                src="https://app.powerbi.com/reportEmbed?reportId=d8ecc4c0-1a54-4b20-ab9d-e42a778c35df&autoAuth=true&ctid=ae087f2d-8a82-46dd-a538-38bebd294479"
                className={styles.embed}
            >
            </iframe>
        </div>
    </Layout>
);

export default AnalyticsREEMEAPage;
